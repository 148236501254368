<template>
  <div>{{ type }}</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {object: {type: Object, required: true}},
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            typeAnalyses: 'sanitaire/typeAnalyses'
        }),
        type(){
            let t = this.typeAnalyses.find(item => item.uid === this.object.typeAnalyse)
            if(t) return t.libelle
            return '-'
        }
    }
}
</script>

<style>

</style>